import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';
import DefaultLayout from 'layouts/general';
import VerifiedApp, { UserPermission } from 'VerifiedApp';
import PrivateRoute from 'routes/PrivateRoute';
import AuthLayout from 'layouts/auth';
import { adminRoutes, generalRoutes, residentRoutes, authRoutes } from 'routes';
import { ESTATEADMIN, RESIDENT, SUPERADMIN } from 'utility/constants';
import { superAdminRoutes } from 'routes/super_admin';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RouterComponent = () => {
  const hasPermission = UserPermission();

  return (
    <VerifiedApp>
      <>
        <Router>
          <ScrollToTop />

          <Routes>
            {/* auth routes */}
            <Route path="/" element={<AuthLayout />}>
              {authRoutes.map(({ path, element: Element }) => {
                return <Route key={path} path={path} element={<Element />} />;
              })}
            </Route>

            {/* general routes */}
            <Route path="/">
              {generalRoutes.map(({ path, element: Element, layout }) => {
                if (layout) {
                  return (
                    <Route key={path} element={<DefaultLayout />}>
                      <Route path={path} element={<Element />} />
                    </Route>
                  );
                }

                return <Route key={path} path={path} element={<Element />} />;
              })}
            </Route>

            {/*  admin routes */}
            <Route path="/">
              <Route element={<AuthLayout />} path="/admin">
                {adminRoutes.publicRoutes?.map(
                  ({ path, element: Element, useAuth }) => {
                    const PageComponent = PrivateRoute(Element);
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            useAuth={useAuth}
                            userRole={ESTATEADMIN}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>

              <Route element={<DefaultLayout />} path="/admin">
                {adminRoutes.privateRoutes?.map(
                  ({
                    path,
                    element: Element,
                    useAuth,
                    permission,
                    checkInvoice
                  }) => {
                    const PageComponent = PrivateRoute(Element);

                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            hasPermission={hasPermission(permission)}
                            userRole={ESTATEADMIN}
                            useAuth={useAuth}
                            checkPendingInvoice={checkInvoice}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>
            </Route>

            {/*  super_admin routes */}
            <Route path="/">
              <Route element={<AuthLayout />} path="/super-admin">
                {superAdminRoutes.publicRoutes?.map(
                  ({ path, element: Element, useAuth }) => {
                    const PageComponent = PrivateRoute(Element);
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            useAuth={useAuth}
                            userRole={SUPERADMIN}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>

              <Route element={<DefaultLayout />} path="/super-admin">
                {superAdminRoutes.privateRoutes?.map(
                  ({ path, element: Element, useAuth, permission }) => {
                    const PageComponent = PrivateRoute(Element);

                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            hasPermission={hasPermission(permission)}
                            userRole={SUPERADMIN}
                            useAuth={useAuth}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>
            </Route>

            {/*  resident routes */}
            <Route path="/">
              <Route element={<AuthLayout />}>
                {residentRoutes.publicRoutes?.map(
                  ({ path, element: Element, useAuth }) => {
                    const PageComponent = PrivateRoute(Element);
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            userRole={RESIDENT}
                            useAuth={useAuth}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>

              <Route element={<DefaultLayout />}>
                {residentRoutes.privateRoutes?.map(
                  ({ path, element: Element, useAuth, permission }) => {
                    const PageComponent = PrivateRoute(Element);

                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <PageComponent
                            hasPermission={hasPermission(permission)}
                            userRole={RESIDENT}
                            useAuth={useAuth}
                          />
                        }
                      />
                    );
                  }
                )}
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Router>
      </>
    </VerifiedApp>
  );
};

export default RouterComponent;
