export const PAGE_SIZE = 10;
export const PAGE_SIZE_NOTIFICATION = 20;

export const ESTATEADMIN = 'ESTATEADMIN';
export const RESIDENT = 'RESIDENT';
export const SUPERADMIN = 'SUPERADMIN';

export const STATUS = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DISABLED', label: 'Disabled' }
];
export const STATUS_S = [
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' }
];

export const STATUS_OPEN = [
  { value: 'CLOSED', label: 'Closed' },
  { value: 'OPEN', label: 'Open' }
];

export const STATUS_APPROVE = [
  { value: 'APPROVED', label: 'Approved' },
  { value: 'PENDING', label: 'Pending' }
];

export const STATUS_DRAFT = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Published' }
];

export const STATUS_BOOKING = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SIGN_IN', label: 'Sign In' },
  { value: 'SIGN_OUT', label: 'Sign Out' },
  { value: 'REJECTED', label: 'Rejected' }
];

export const STATUS_IN = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' }
];

export const OPTIONS = [
  { value: 'power', label: 'Power' },
  { value: 'Service', label: 'Service' },
  { value: 'Top_Up', label: 'Top Up' },
  { value: 'water', label: 'Water' },
  { value: 'Other', label: 'Others' }
];

export const PROPERTY_TYPE = [
  { value: 'all', label: 'All' },
  { value: 'Semi Detached', label: 'Semi Detached' },
  { value: 'Metro', label: 'Metro' }
];

export const PARTNER_TYPE = [
  { value: 'all', label: 'All' },
  { value: 'Occupied', label: 'Occupied' },
  { value: 'Vacant', label: 'Vacant' },
  { value: 'Holiday Homes', label: 'Holiday Homes' },
  { value: 'Prepaid Service Charges', label: 'Prepaid Service Charges' },
  { value: 'Commercial', label: 'Commercial' }
];

export const FREQUENCY = [
  // { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'SEMI_ANNUAL', label: 'Semi annual' },
  { value: 'YEARLY', label: 'Yearly' }
];

export const RESIDENCY_TYPE = [
  { value: '', label: 'Choose' },
  { value: 'Tenant', label: 'Tenant' },
  { value: 'Landlord', label: 'Landlord' }
];

export const PRICE_TYPE = [
  { value: 'FIXED', label: 'Fixed' },
  { value: 'DYNAMIC', label: 'Dynamic' }
];

export const STATUS_FILTER = [
  { value: '', label: 'Choose' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PARTIAL', label: 'Partial' },
  { value: 'PAID', label: 'Paid' }
];

export const ROLES = [
  { value: 'RESIDENT', label: 'Resident' },
  { value: 'ESTATEADMIN', label: 'Estate Admin' },
  { value: 'SUBRESIDENT', label: 'Sub Resident' },
  { value: 'SUPERADMIN', label: 'Super Admin' }
];

export const period = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
  { label: 'Custom', value: 'custom' }
];

export const DASHBOARD_PERIOD = {
  period1: [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' }
  ],
  period2: [
    { label: 'Year', value: 'year' },
    { label: 'Custom', value: 'custom' }
  ]
};

export const selectOptionsAdmin = [
  {
    label: 'Paid',
    value: 'paid'
  },
  {
    label: 'Outstanding',
    value: 'outstanding'
  },
  {
    label: 'Wallet',
    value: 'wallet'
  },
  {
    label: 'Residents',
    value: 'residents'
  },
  {
    label: 'Visitor Booking',
    value: 'bookings'
  },
  {
    label: 'Tokens',
    value: 'tokens'
  }
];

export const residentsSelectoption = [
  {
    label: 'Wallet',
    value: 'wallet'
  },
  {
    label: 'Visitor Booking',
    value: 'BOOKINGS'
  },
  {
    label: 'Paid',
    value: 'PAID'
  },
  {
    label: 'Outstanding',
    value: 'outstanding'
  }
];

export const superAdminDashboardChartOptions = [
  {
    label: 'Paid',
    value: 'Paid'
  },
  {
    label: 'Visitor Booking',
    value: 'Bookings'
  },
  {
    label: 'Outstanding',
    value: 'Outstanding'
  },
  {
    label: 'Property',
    value: 'Property'
  },
  {
    label: 'Residents',
    value: 'Residents'
  },
  {
    label: 'Tokens',
    value: 'Tokens'
  },
  {
    label: 'Wallet',
    value: 'Wallet'
  }
];

export const chartPlugins = [
  {
    afterDraw(chart) {
      if (chart.data.datasets[0].data.length < 1) {
        const { ctx } = chart;
        const { width } = chart;
        const { height } = chart;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '16px Arial';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
      }
    }
  }
];

export const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  bezierCurve: true,
  elements: {
    line: {
      tension: 0.5
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: false
      // text: 'Chart.js Line Chart'
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    }
  }
};

export const BILLTYPES = {
  power: 'POWER',
  service: 'SERVICE',
  topup: 'TOPUP',
  cart: 'CART'
};

export const ITEMTYPES = { outstanding: 'OUTSTANDING' };
export const PRICINGTYPES = { fixed: 'FIXED', dynamic: 'DYNAMIC' };

export const BILL_HEADERS = [
  'Order No',
  'Bill',
  'Amount',
  'Date',
  'Status',
  ''
];

export const OUTSTANDING_HEADERS = [
  'Order No',
  'Bill',
  'Amount',
  'Date',
  'Status',
  ''
];

export const DATE_FILTERS = [
  { value: 'month', label: 'Last 30 Days' },
  { value: 'week', label: 'Last 7 Days' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'day', label: 'Today' },
  { value: 'custom', label: 'Custom' }
];

export const STATUS_FILTERS = [
  { value: 'ALL', label: 'All' },
  { value: 'PAID', label: 'Paid' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'FAILED', label: 'Failed' }
];

export const STATUS_DRAFT_EXTRA = [
  { value: 'DISABLED', label: 'Disabled' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Published' }
];

export const STATUS_PAID_PENDING = [
  { value: 'PAID', label: 'Paid' },
  { value: 'PENDING', label: 'Pending' }
];

export const STATUS_PENDING_APPROVAL = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DISABLED', label: 'Disabled' },
  { value: 'PENDING_APPROVAL', label: 'Pending approval' }
];

export const VENDING = [
  { value: 'powerpay', label: 'powerpay' },
  { value: 'momas', label: 'momas' },
  { value: 'demo', label: 'demo' },
  { value: 'vgg', label: 'vgg' }
];

export const STATUS_SETTLEMENT = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'SETTLED', label: 'Settled' }
];

export const DURATION = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'YEARLY', label: 'Yearly' }
];

export const YES_NO_STATUS = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' }
];

export const INVOICE_STATUS = [
  { value: 'PAID', label: 'Paid' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'CANCELLED', label: 'Cancelled' }
];
