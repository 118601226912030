import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { handleApiError } from 'utility';
import deleteicon from 'assets/images/delete.png';
import { Modal, Button } from 'components/ui';

const ConfirmationModal = ({
  show,
  handleClose,
  mutationFunc,
  refetch,
  title,
  message,
  subMessage,
  actionButtonText,
  hideIcon,
  successMessage
}) => {
  const { mutate, isLoading: isDeleting } = useMutation(mutationFunc, {
    onError: (error) => {
      toast.error(handleApiError(error));
    },
    onSuccess: () => {
      toast.success(successMessage || 'Deleted successfully');
      refetch();
      handleClose();
    }
  });

  const { mutate: update, isLoading: updating } = useMutation(mutationFunc, {
    enabled: !!show?.id,
    onError: (error) => {
      toast.error(handleApiError(error));
    },
    onSuccess: () => {
      toast.success(successMessage || 'Deleted successfully');
      refetch();
      handleClose();
    }
  });

  const handelDelete = () => {
    // if (!show?.id) return;
    if (show?.id && show?.payload) {
      update({ id: show?.id, payload: show?.payload });
    } else if (show?.id) {
      mutate({ id: show?.id });
    } else if (show?.payload) {
      mutate({ payload: show?.payload });
    }
  };

  return (
    <Modal
      show={!!show}
      className="property_modal"
      handleClose={() => handleClose()}
      title={title || 'Confirm Delete'}
      centered
    >
      <div className="w-100 d-grid align-content-center vh-25 text-center p-4">
        {hideIcon ? null : (
          <img
            src={deleteicon}
            alt="delete warning"
            className="mb-5 m-auto"
            style={{ width: '100px', height: '100px' }}
          />
        )}
        <h4>
          {message ||
            `Are you sure you want to delete ${show?.name || 'this resource'}?`}
        </h4>
        <p>
          {subMessage === undefined
            ? 'This action cant be undone.'
            : subMessage}
        </p>
      </div>
      <section className="d-flex justify-content-center align-items-center pt-5">
        <Button
          onClick={handelDelete}
          variant="secondary"
          loading={isDeleting || updating}
        >
          {actionButtonText || 'Yes Delete'}
        </Button>

        <Button className="btn--outline ms-4" onClick={() => handleClose()}>
          Cancel
        </Button>
      </section>
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  show: PropTypes.object,
  handleClose: PropTypes.func,
  mutationFunc: PropTypes.func,
  refetch: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  actionButtonText: PropTypes.string,
  successMessage: PropTypes.string,
  hideIcon: PropTypes.bool
};
