import Api from './axios';
import { queryParamsHelper } from './query';

export const login = async (payload) => {
  return Api.post('auth/login/', payload).then((res) => res.data);
};

export const verifyOtp = async (payload) => {
  return Api.post('auth/users/verify-token/', payload).then((res) => res.data);
};

export const resendOtp = async (payload) => {
  return Api.post('auth/users/signup/resend/', payload).then((res) => res.data);
};

export const forgotPassword = async (payload) => {
  return Api.post('auth/users/reset-password/', payload).then(
    (res) => res.data
  );
};

export const createPassword = async (payload) => {
  return Api.post('auth/users/create-password/', payload).then(
    (res) => res.data
  );
};

export const verifyDomain = async (payload) => {
  return Api.post('estate/domain/verification/', payload).then(
    (res) => res.data
  );
};

export const switchLinkedUser = async (payload) => {
  return Api.post('auth/users/authenticate/linked-accounts/', payload).then(
    (res) => res.data
  );
};

export const createBill = async (payload) => {
  return Api.post('bill/', payload).then((res) => res.data);
};

export const editOrDeleteBill = async (data) => {
  if (data?.payload) {
    return Api.patch(`bill/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`bill/${data?.id}/`, data?.payload).then((res) => res.data);
};

export const createEstateProperty = async (payload) => {
  return Api.post('estate/property/', payload).then((res) => res.data);
};

export const editOrDeleteEstateProperty = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/property/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/property/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createEstatePartner = async (payload) => {
  return Api.post('estate/partner/', payload).then((res) => res.data);
};

export const editOrDeleteEstatePartner = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/partner/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/partner/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createOutstandingBill = async (payload) => {
  return Api.post('outstanding/', payload).then((res) => res.data);
};

export const editOrDeleteOutstandingBill = async (data) => {
  if (data?.payload) {
    return Api.patch(`outstanding/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`outstanding/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createEditOrDeleteEstateInfo = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/information/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  if (data?.id && !data?.payload) {
    return Api.delete(`estate/information/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.post(`estate/information/`, data?.payload).then((res) => res.data);
};

export const createEstateInfo = async (data) => {
  return Api.post(`estate/information/`, data).then((res) => res.data);
};

export const createEstateRequest = async (data) => {
  return Api.post(`estate/request/data/`, data).then((res) => res.data);
};

export const editOrDeleteEstateRequestData = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/request/data/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/request/data/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const deleteEstateRequestDataFile = async (data) => {
  return Api.delete(
    `estate/request/data/${data?.id}/file/delete/`,
    data?.payload
  ).then((res) => res.data);
};

export const editOrDeleteEstateCategory = async (data) => {
  if (data?.payload) {
    return Api.patch(
      `estate/request/category/${data?.id}/`,
      data?.payload
    ).then((res) => res.data);
  }
  return Api.delete(`estate/request/category/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const editOrDeleteTicketCategory = async (data) => {
  if (data?.payload) {
    return Api.patch(`ticket/category/data/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`ticket/category/data/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createTicketCategory = async (data) => {
  return Api.post(`ticket/category/data/`, data).then((res) => res.data);
};

export const createEstateCategory = async (data) => {
  return Api.post(`estate/request/category/`, data).then((res) => res.data);
};

export const editOrDeleteEstatePoll = async (data) => {
  if (data?.payload) {
    return Api.patch(`poll/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`poll/${data?.id}/`, data?.payload).then((res) => res.data);
};

export const createEstatePoll = async (data) => {
  return Api.post(`poll/`, data).then((res) => res.data);
};

export const createComplaint = async (data) => {
  return Api.post(`ticket/`, data).then((res) => res.data);
};

export const createComplaintStatus = async (data) => {
  return Api.post(`ticket/${data?.id}/status/`, data?.payload).then(
    (res) => res.data
  );
};

export const createComplaintReply = async (data) => {
  return Api.post(`ticket/reply/`, data).then((res) => res.data);
};

export const createResident = async (data) => {
  return Api.post(`auth/users/signup/`, data).then((res) => res.data);
};

export const updatDeleteResident = async (data) => {
  if (data?.payload) {
    return Api.patch(`auth/users/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`auth/users/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const updateUser = async (data) => {
  if (data?.payload) {
    return Api.patch(`auth/users/${data?.id}/update/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`auth/users/${data?.id}/update/`, data?.payload).then(
    (res) => res.data
  );
};

export const createUser = async (data) => {
  if (data?.payload) {
    return Api.post(`auth/users/`, data?.payload).then((res) => res.data);
  }
};

export const updatDeleteBooking = async (data) => {
  if (data?.payload) {
    return Api.patch(`booking/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`booking/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createBooking = async (data) => {
  return Api.post(`booking/`, data).then((res) => res.data);
};

export const createGroup = async (data) => {
  return Api.post(`auth/groups/`, data).then((res) => res.data);
};

export const updateDeleteGroup = async (data) => {
  if (data?.payload) {
    return Api.patch(`auth/groups/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`auth/groups/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const regenerateToken = async (data) => {
  return Api.post(`order/${data?.id}/token/regenerate/`, data).then(
    (res) => res.data
  );
};

export const createAds = async (data) => {
  return Api.post(`advert/`, data).then((res) => res.data);
};

export const updateDeleteAds = async (data) => {
  if (data?.payload) {
    return Api.patch(`advert/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`advert/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const deleteAdsImg = async (id) => {
  return Api.delete(`advert/${id}/images/delete/`).then((res) => res.data);
};

export const walletFund = async (data) => {
  return Api.post(`wallet/fund/`, data).then((res) => res.data);
};

export const vote = async (data) => {
  return Api.post(`poll/vote/`, data).then((res) => res.data);
};

export const addCartItem = async (data) => {
  return Api.post(`cart/`, data).then((res) => res.data);
};

export const updateCartItem = async (data) => {
  return Api.patch(`cart/${data?.id}/`, data?.payload).then((res) => res.data);
};

export const deleteCartItem = async (id) => {
  return Api.delete(`cart/${id}/`).then((res) => res.data);
};

export const clearCartItems = async () => {
  return Api.delete(`cart/clear/`).then((res) => res.data);
};

export const checkoutCartItems = async () => {
  return Api.post(`cart/checkout/`).then((res) => res.data);
};

export const fetchCheckout = async (id) => {
  return Api.post(`order/${id}/pay/`).then((res) => res.data);
};

export const outstandingImport = async (file) => {
  return Api.post(`outstanding/import/csv/`, file).then((res) => res.data);
};
export const createManualToken = async (payload) => {
  return Api.post(`order/token/manual/generate/`, payload).then(
    (res) => res.data
  );
};
export const createEstateBasic = async (payload) => {
  return Api.post(`estate/data/`, payload).then((res) => res.data);
};

export const mutateEstate = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/data/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/data/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
export const createEstateVending = async (payload) => {
  return Api.post(`estate/vending/`, payload).then((res) => res.data);
};

export const mutateEstateVending = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/vending/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/vending/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createEstateGateway = async (payload) => {
  return Api.post(`estate/payment/`, payload).then((res) => res.data);
};

export const mutateEstateGateway = async (data) => {
  if (data?.payload) {
    return Api.patch(`estate/payment/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`estate/payment/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};

export const createEstateSettlementAccount = async (payload) => {
  return Api.post(`settlement/`, payload).then((res) => res.data);
};

export const mutateEstateSettlementAccount = async (data) => {
  if (data?.payload) {
    return Api.patch(`settlement/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`settlement/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
export const createEstateSettlementRequest = async (data) => {
  const queryParams = queryParamsHelper(data.payload);
  if (queryParams) {
    return Api.post(`settlement/request/create/${queryParams}`, {}).then(
      (res) => res.data
    );
  }
  return Api.post(`settlement/request/create/`, data.payload).then(
    (res) => res.data
  );
};
export const mutateEstateSettlementRequest = async (data) => {
  if (data?.payload) {
    return Api.patch(`settlement/request/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`settlement/request/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
export const createClearTamperToken = async (payload) => {
  return Api.post(`meter/clear-tamper/generate/`, payload).then(
    (res) => res.data
  );
};
export const createPlan = async (payload) => {
  return Api.post('billing/plans/', payload).then((res) => res.data);
};
export const editOrDeletePlan = async (data) => {
  if (data?.payload) {
    return Api.patch(`billing/plans/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`billing/plans/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
export const createPlanPricing = async (payload) => {
  return Api.post('billing/plans/pricing/', payload).then((res) => res.data);
};
export const editOrDeletePlanPricing = async (data) => {
  if (data?.payload) {
    return Api.patch(`billing/plans/pricing/${data?.id}/`, data?.payload).then(
      (res) => res.data
    );
  }
  return Api.delete(`billing/plans/pricing/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
export const generateInvoice = async (payload) => {
  return Api.post('billing/invoices/generate/', payload).then(
    (res) => res.data
  );
};
export const updateInvoiceStatus = async (data) => {
  return Api.post(
    `billing/invoices/${data?.id}/update-status/`,
    data?.payload
  ).then((res) => res.data);
};
export const deleteInvoice = async (data) => {
  return Api.delete(`billing/invoices/${data?.id}/`, data?.payload).then(
    (res) => res.data
  );
};
