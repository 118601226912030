import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo-white.png';
import SmallLogo from 'assets/images/logo-white-small.png';
import { logOut } from 'services/auth';
import { closeRefDropdown } from 'utility';
import { NavDropdown } from '../../../components/ui';

const SuperAdminSidebar = ({ smallSidebar, onNavigate }) => {
  const navigate = useNavigate();
  const complaintsRef = useRef(null);
  const estateRequestRef = useRef(null);
  const paymentHistoryRef = useRef(null);
  const useManagementRef = useRef(null);
  const location = useLocation();

  const closeAllDropdowns = () => {
    closeRefDropdown(complaintsRef);
    closeRefDropdown(estateRequestRef);
    closeRefDropdown(paymentHistoryRef);
    closeRefDropdown(useManagementRef);
  };

  useEffect(() => {
    if (smallSidebar) {
      closeAllDropdowns();
    }
  }, [smallSidebar]);

  return (
    <div className="sidebar-inner">
      <NavLink
        to="/"
        onClick={onNavigate}
        className={classnames('sidebar__logo', {
          'sidebar__logo--small': smallSidebar
        })}
      >
        <img
          src={Logo}
          className="img-fluid desktop-logo"
          alt="facility bills"
        />

        <img
          src={SmallLogo}
          className="img-fluid small-logo"
          alt="facility bills"
        />
      </NavLink>

      <nav>
        <NavLink
          to="/super-admin/dashboard"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-dashboard sidebar__icon" />
          <span className="sidebar__text">Dashboard</span>
        </NavLink>

        <NavLink
          to="/super-admin/estate"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-house sidebar__icon" />
          <span className="sidebar__text">Estate</span>
        </NavLink>

        <NavLink
          to="/super-admin/announcement"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-speaker sidebar__icon" />
          <span className="sidebar__text">Announcement </span>
        </NavLink>

        <NavLink
          to="/super-admin/wallet"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-card-receive sidebar__icon" />
          <span className="sidebar__text">Wallets</span>
        </NavLink>
        <NavDropdown
          ref={paymentHistoryRef}
          title="Billing"
          icon="icon-receipt"
          className={classnames({
            activeClass: location.pathname.includes('/super-admin/billing')
          })}
        >
          <li>
            <NavLink
              to="/super-admin/billing/plans"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive
                })
              }
            >
              Plans
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/super-admin/billing/invoices"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive
                })
              }
            >
              Invoices
            </NavLink>
          </li>
        </NavDropdown>
        <NavLink
          to="/super-admin/manual-token"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-activity sidebar__icon" />
          <span className="sidebar__text">Manual Token</span>
        </NavLink>

        <NavLink
          to="/super-admin/users"
          onClick={onNavigate}
          className={({ isActive }) =>
            classnames('sidebar__link', {
              activeClass: isActive
            })
          }
        >
          <span className="icon-user-square sidebar__icon" />
          <span className="sidebar__text">Super User Management</span>
        </NavLink>

        <NavDropdown
          ref={paymentHistoryRef}
          title="Report"
          icon="icon-estate-info"
          className={classnames({
            activeClass: location.pathname.includes('/super-admin/report')
          })}
        >
          <li>
            <NavLink
              to="/super-admin/report/transactions"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive
                })
              }
            >
              Transactions
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/super-admin/report/settlements"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive
                })
              }
            >
              Settlements
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/super-admin/report/settlement-requests"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive
                })
              }
            >
              Settlement Requests
            </NavLink>
          </li>
        </NavDropdown>

        <div
          role="link"
          className="sidebar__link logout_link"
          onClick={() =>
            logOut(() => {
              onNavigate();
              setTimeout(() => navigate('/login'), 300);
            })
          }
        >
          <span className="icon-logout sidebar__icon" />
          <span className="sidebar__text">Log Out</span>
        </div>
      </nav>
    </div>
  );
};

SuperAdminSidebar.propTypes = {
  smallSidebar: PropTypes.bool,
  onNavigate: PropTypes.func
};

export default SuperAdminSidebar;
