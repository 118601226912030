import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo-white.png';
import SmallLogo from 'assets/images/logo-white-small.png';
import { AccessControl, UserPermission } from 'VerifiedApp';
import { NavDropdown } from 'components/ui';
import { logOut } from 'services/auth';
import { closeRefDropdown } from 'utility';
import useSubdomain from '../../../services/useSubdomain';

const AdminSidebar = ({ smallSidebar, onNavigate }) => {
  const navigate = useNavigate();
  const hasPermission = UserPermission();
  const location = useLocation();
  const { domainObject } = useSubdomain();

  const complaintsRef = useRef(null);
  const estateRequestRef = useRef(null);
  const paymentHistoryRef = useRef(null);
  const useManagementRef = useRef(null);
  const closeAllDropdowns = () => {
    closeRefDropdown(complaintsRef);
    closeRefDropdown(estateRequestRef);
    closeRefDropdown(paymentHistoryRef);
    closeRefDropdown(useManagementRef);
  };

  useEffect(() => {
    if (smallSidebar) {
      closeAllDropdowns();
    }
  }, [smallSidebar]);

  return (
    <div className="sidebar-inner">
      <NavLink
        to="/"
        onClick={onNavigate}
        className={classnames('sidebar__logo', {
          'sidebar__logo--small': smallSidebar
        })}
      >
        <img
          src={Logo}
          className="img-fluid desktop-logo"
          alt="facility bills"
        />

        <img
          src={SmallLogo}
          className="img-fluid small-logo"
          alt="facility bills"
        />
      </NavLink>

      <nav>
        {domainObject?.pending_invoices === 0 && (
          <>
            <NavLink
              to="/admin/dashboard"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('dashboard_management')
                })
              }
            >
              <span className="icon-dashboard sidebar__icon" />
              <span className="sidebar__text">Dashboard</span>
            </NavLink>

            <NavLink
              to="/admin/bills"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_bill')
                })
              }
            >
              <span className="icon-empty-wallet sidebar__icon" />
              <span className="sidebar__text">Bills</span>
            </NavLink>

            <NavLink
              to="/admin/property-type"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_propertytype')
                })
              }
            >
              <span className="icon-house sidebar__icon" />
              <span className="sidebar__text">Property Type</span>
            </NavLink>

            <NavLink
              to="/admin/partner-type"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_partnertype')
                })
              }
            >
              <span className="icon-user-square sidebar__icon" />
              <span className="sidebar__text">Partner Type</span>
            </NavLink>

            <AccessControl permission={'payment_history_management'}>
              <NavDropdown
                ref={paymentHistoryRef}
                title="Payment History"
                icon="icon-message-text"
                className={classnames({
                  activeClass: location.pathname.includes(
                    '/admin/payment-history'
                  )
                })}
              >
                <li>
                  <NavLink
                    to="/admin/payment-history?bill_type=POWER"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('bill_type=POWER'),
                      noPermission: !hasPermission('payment_history_management')
                    })}
                  >
                    Power
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/payment-history?bill_type=service"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass:
                        location.search.includes('bill_type=service'),
                      noPermission: !hasPermission('payment_history_management')
                    })}
                  >
                    Service
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/payment-history?bill_type=Top_Up"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('bill_type=Top_Up'),
                      noPermission: !hasPermission('payment_history_management')
                    })}
                  >
                    Top Up
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/payment-history?bill_type=water"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('bill_type=water'),
                      noPermission: !hasPermission('payment_history_management')
                    })}
                  >
                    Water
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/payment-history?bill_type=Other"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('bill_type=Other'),
                      noPermission: !hasPermission('payment_history_management')
                    })}
                  >
                    Others
                  </NavLink>
                </li>
              </NavDropdown>
            </AccessControl>

            <NavLink
              to="/admin/outstanding-payment"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_outstandingbill')
                })
              }
            >
              <span className="icon-activity sidebar__icon" />
              <span className="sidebar__text">Outstanding Bills</span>
            </NavLink>

            {/* <NavLink to="/admin/credit"   className={({ isActive }) =>
              isActive ? 'activeClass' : 'sidebar__link'
            }>
            <span className="icon-card-receive sidebar__icon" />
            <span className="sidebar__text">Credits</span>
          </NavLink> */}

            <NavLink
              to="/admin/wallet"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('wallet_management')
                })
              }
            >
              <span className="icon-card-receive sidebar__icon" />
              <span className="sidebar__text">Wallets</span>
            </NavLink>
          </>
        )}
        <AccessControl permission={'billing_management'}>
          <NavDropdown
            ref={paymentHistoryRef}
            title="Billing"
            icon="icon-receipt"
            className={classnames({
              activeClass: location.pathname.includes('/admin/billing')
            })}
          >
            <li>
              <NavLink
                to="/admin/billing/invoices"
                onClick={onNavigate}
                className={({ isActive }) =>
                  classnames('sidebar__link', {
                    activeClass: isActive
                  })
                }
              >
                Invoices
              </NavLink>
            </li>
          </NavDropdown>
        </AccessControl>
        {domainObject?.pending_invoices === 0 && (
          <>
            <NavLink
              to="/admin/resources"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_information')
                })
              }
            >
              <span className="icon-estate-info sidebar__icon" />
              <span className="sidebar__text">Estate Information</span>
            </NavLink>

            <AccessControl
              permission={['view_requestcategory', 'view_request']}
            >
              <NavDropdown
                ref={estateRequestRef}
                title="Estate Request"
                icon="icon-receipt"
                className={classnames({
                  activeClass: location.pathname.includes('/admin/requests')
                })}
              >
                <li>
                  <NavLink
                    to="/admin/requests/me?type=all"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('view_request')
                      })
                    }
                  >
                    My Requests
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/requests/"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('view_request')
                      })
                    }
                  >
                    Requests
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/requests/approved"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('view_request')
                      })
                    }
                  >
                    Approvals
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/requests/category"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('view_requestcategory')
                      })
                    }
                  >
                    Categories
                  </NavLink>
                </li>
              </NavDropdown>
            </AccessControl>

            <NavLink
              to="/admin/polls"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_poll')
                })
              }
            >
              <span className="icon-chart sidebar__icon" />
              <span className="sidebar__text">Polls</span>
            </NavLink>

            <AccessControl permission={'complaint_management'}>
              <NavDropdown
                ref={complaintsRef}
                title="Complaints"
                icon="icon-message-question"
                className={classnames({
                  activeClass: location.pathname.includes('/admin/complaints')
                })}
              >
                <li>
                  <NavLink
                    to="/admin/complaints?status=open"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('status=open'),
                      noPermission: !hasPermission('complaint_management')
                    })}
                  >
                    Open
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/complaints?status=closed"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes('status=closed'),
                      noPermission: !hasPermission('complaint_management')
                    })}
                  >
                    Closed
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/complaints-category"
                    onClick={onNavigate}
                    className={classnames('sidebar__link', {
                      activeClass: location.search.includes(
                        'complaints-category'
                      )
                      // noPermission: !hasPermission('complaint_management')
                    })}
                  >
                    Category
                  </NavLink>
                </li>
              </NavDropdown>
            </AccessControl>

            <AccessControl
              permission={[
                'view_user',
                'residents_management',
                'kct_management',
                'role_management',
                'manual_token_generation',
                'clear_tamper_generation'
              ]}
            >
              <NavDropdown
                ref={useManagementRef}
                title="User Management"
                icon="icon-personal-card"
                className={classnames({
                  activeClass: location.pathname.includes('/admin/user')
                })}
              >
                <li>
                  <NavLink
                    to="/admin/user/residents"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('residents_management')
                      })
                    }
                  >
                    Residents
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/user/resident-ktc"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('kct_management')
                      })
                    }
                  >
                    KCTs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/user/roles"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('role_management')
                      })
                    }
                  >
                    Roles
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/user/users"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('view_user')
                      })
                    }
                  >
                    Users
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/user/manual-token"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('manual_token_generation')
                      })
                    }
                  >
                    Manual Token
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/user/clear-tamper"
                    onClick={onNavigate}
                    className={({ isActive }) =>
                      classnames('sidebar__link', {
                        activeClass: isActive,
                        noPermission: !hasPermission('clear_tamper_generation')
                      })
                    }
                  >
                    Clear Tamper
                  </NavLink>
                </li>
              </NavDropdown>
            </AccessControl>

            <NavLink
              to="/admin/booking-managemnent"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('booking_management')
                })
              }
            >
              <span className="icon-document-text sidebar__icon" />
              <span className="sidebar__text">Visitor Management</span>
            </NavLink>
            <NavDropdown
              ref={paymentHistoryRef}
              title="Report"
              icon="icon-receipt-edit"
              className={classnames({
                activeClass: location.pathname.includes('/super-admin/report')
              })}
            >
              <li>
                <NavLink
                  to="/admin/report/transactions"
                  onClick={onNavigate}
                  className={({ isActive }) =>
                    classnames('sidebar__link', {
                      activeClass: isActive,
                      noPermission: !hasPermission('report_management')
                    })
                  }
                >
                  Transactions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/report/settlements"
                  onClick={onNavigate}
                  className={({ isActive }) =>
                    classnames('sidebar__link', {
                      activeClass: isActive,
                      noPermission: !hasPermission('report_management')
                    })
                  }
                >
                  Settlements
                </NavLink>
              </li>
            </NavDropdown>

            <NavLink
              to="/admin/advert"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_advert')
                })
              }
            >
              <span className="icon-speaker sidebar__icon" />
              <span className="sidebar__text">Advert</span>
            </NavLink>

            <NavLink
              to="/admin/activity-log"
              onClick={onNavigate}
              className={({ isActive }) =>
                classnames('sidebar__link', {
                  activeClass: isActive,
                  noPermission: !hasPermission('view_activity_log')
                })
              }
            >
              <span className="icon-diagram sidebar__icon" />
              <span className="sidebar__text">Activity Log</span>
            </NavLink>
          </>
        )}
        <div
          role="link"
          className="sidebar__link logout_link"
          onClick={() =>
            logOut(() => {
              onNavigate();
              setTimeout(() => navigate('/login'), 300);
            })
          }
        >
          <span className="icon-logout sidebar__icon" />
          <span className="sidebar__text">Log Out</span>
        </div>
      </nav>
    </div>
  );
};

AdminSidebar.propTypes = {
  smallSidebar: PropTypes.bool,
  onNavigate: PropTypes.func
};

export default AdminSidebar;
