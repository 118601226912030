import React from 'react';
import PropTypes from 'prop-types';

const ErrorField = ({ hasError, label, name }) => {
  return (
    <>
      <small
        style={{
          color: '#F48989',
          fontSize: 12,
          display: hasError ? 'block' : 'none'
        }}
      >
        {label || name} is required
      </small>
    </>
  );
};
export default ErrorField;

ErrorField.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string
};
