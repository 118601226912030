import { lazy } from 'react';

const privateRoutes = [
  {
    path: '',
    element: lazy(() => import('pages/super_admin/Dashboard')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/dashboard',
    element: lazy(() => import('pages/super_admin/Dashboard')),
    useAuth: false,
    permission: ''
  },
  {
    path: '/super-admin/estate',
    element: lazy(() => import('pages/super_admin/Estate')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/estate/upsert/:id',
    element: lazy(() => import('pages/super_admin/Estate/UpsertEstate')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/announcement',
    element: lazy(() => import('pages/super_admin/Announcement')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/wallet',
    element: lazy(() => import('pages/super_admin/Wallet')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/manual-token',
    element: lazy(() => import('pages/super_admin/ManualToken')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/users',
    element: lazy(() => import('pages/super_admin/UserManagement')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/transactions',
    element: lazy(() => import('pages/super_admin/Report')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/settlements',
    element: lazy(() => import('pages/super_admin/Settlement')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/settlements/:id/transactions',
    element: lazy(() =>
      import('pages/super_admin/Settlement/SettlementAccountTransactions')
    ),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/settlement-requests',
    element: lazy(() =>
      import('pages/super_admin/Settlement/SettlementRequest')
    ),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/settlement-requests/:id/accounts',
    element: lazy(() =>
      import('pages/super_admin/Settlement/SettlementRequestAccounts')
    ),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/report/settlement-requests/:id/transactions',
    element: lazy(() =>
      import(
        'pages/super_admin/Settlement/SettlementRequestAccountTransactions'
      )
    ),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/profile',
    element: lazy(() => import('pages/admin/UserProfile')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/super-admin/invoice',
    element: lazy(() => import('pages/admin/Invoice')),
    useAuth: true
  },
  {
    path: '/super-admin/billing/plans',
    element: lazy(() => import('pages/super_admin/Billing/Plans')),
    useAuth: true
  },
  {
    path: '/super-admin/billing/plan/create',
    element: lazy(() => import('pages/super_admin/Billing/Plans/CreatePlan')),
    useAuth: true
  },
  {
    path: '/super-admin/billing/plan/:id/edit',
    element: lazy(() => import('pages/super_admin/Billing/Plans/EditPlan')),
    useAuth: true
  },
  {
    path: '/super-admin/billing/invoices',
    element: lazy(() => import('pages/super_admin/Billing/Invoices')),
    useAuth: true
  },
  {
    path: '/super-admin/billing/invoice/generate',
    element: lazy(() =>
      import('pages/super_admin/Billing/Invoices/GenerateInvoice')
    ),
    useAuth: true
  },
  {
    path: '/super-admin/billing/invoice/:id/view',
    element: lazy(() => import('pages/super_admin/Billing/Invoices/Invoice')),
    useAuth: true
  }
];

const publicRoutes = [];

export const superAdminRoutes = {
  privateRoutes,
  publicRoutes
};
