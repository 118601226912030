import { isAuthenticated } from 'services/auth';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { UserContext } from 'contexts/user';
import { AdvertContext } from 'contexts/adverts';
import { allowedRoutes } from 'utility/advertConfig';
import useSubdomain from '../services/useSubdomain';

const PrivateRoute = (Element) => {
  const WrappedComponent = ({
    hasPermission,
    useAuth,
    userRole,
    checkPendingInvoice = true
  }) => {
    const location = useLocation();
    const { roles } = useContext(UserContext);
    const { setShowAdvert } = useContext(AdvertContext);
    const { domainObject } = useSubdomain();

    React.useEffect(() => {
      const showAdvertOnCurrentRoute = allowedRoutes.some((route) =>
        location.pathname.includes(route)
      );

      setShowAdvert(showAdvertOnCurrentRoute);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    // if loggedin user does not have this role redirect to 404
    if (roles && !roles.includes(userRole)) {
      return <Navigate to="/404" />;
    }

    // if route does not have an auth guard show the page
    if (!useAuth) {
      return <Element />;
    }

    // if route has an auth guard do the following checks
    if (isAuthenticated()) {
      if (
        userRole === 'ESTATEADMIN' &&
        checkPendingInvoice &&
        domainObject?.pending_invoices > 0
      ) {
        return <Navigate to="/admin/billing/invoices" />;
      }

      // check if user has the permissions to access the route
      if (hasPermission) {
        return <Element />;
      }
      // redirect if user does not have the permissions to access the route
      return <Navigate to="/404" />;
    }
    // redirect if user is not loggedin and the route has an auth guard
    return <Navigate to="/login" state={{ from: location }} replace />;
  };

  WrappedComponent.propTypes = {
    hasPermission: PropTypes.bool,
    roles: PropTypes.array,
    useAuth: PropTypes.bool,
    userRole: PropTypes.string,
    checkPendingInvoice: PropTypes.bool
  };

  return WrappedComponent;
};

export default PrivateRoute;
